// ../common/src/Config.ts
var Environment = /* @__PURE__ */ ((Environment2) => {
  Environment2["Development"] = "development";
  Environment2["Staging"] = "staging";
  Environment2["Production"] = "production";
  return Environment2;
})(Environment || {});
var defaults = {
  development: {
    BV_FIREBASE_HOST_URL: "https://localhost:8888",
    BV_FIREBASE_API_KEY: "AIzaSyAU0Ym9kfHRJJMdD6S_46BhQy7QUKgpLR0",
    BV_FIREBASE_APP_ID: "1:611358345998:web:b38a2a9f9a5ea316e6a55f",
    BV_FIREBASE_AUTH_DOMAIN: "beyondview-portal.firebaseapp.com",
    BV_FIREBASE_MEASUREMENT_ID: "G-1ZKJ97SG38",
    BV_FIREBASE_MESSAGING_SENDER_ID: "611358345998",
    BV_FIREBASE_PROJECT_ID: "beyondview-portal",
    BV_FIREBASE_STORAGE_BUCKET: "beyondview-portal.appspot.com",
    BV_FIREBASE_STORAGE_PUBLIC_HOST: "http://localhost:9199",
    BV_FIREBASE_STORAGE_PUBLIC_TEMPLATE: "{host}/{bucket}/{object}",
    BV_FIREBASE_STORAGE_RESTRICTED_HOST: "http://localhost:9199",
    BV_FIREBASE_STORAGE_RESTRICTED_TEMPLATE: "{host}/v0/b/{bucket}/o/{object}?alt=media&token={token}",
    BV_FIREBASE_AUTH_PORT: 9099,
    BV_FIREBASE_FIRESTORE_PORT: 8080,
    BV_FIREBASE_SERVER: "localhost",
    BV_FIREBASE_STORAGE_PORT: 9199,
    BV_FIREBASE_PUBSUB_PORT: 8085
  },
  staging: {
    BV_FIREBASE_HOST_URL: "https://staging.beyondview.com",
    BV_FIREBASE_API_KEY: "AIzaSyBtq5R3KmZ-cJwBMy27mZ60uIvqGN-shQ0",
    BV_FIREBASE_APP_ID: "1:189043980068:web:c42ce27fb3577ed953a5b1",
    BV_FIREBASE_AUTH_DOMAIN: "beyondview-portal-staging.firebaseapp.com",
    BV_FIREBASE_MEASUREMENT_ID: "G-486S1V8YY9",
    BV_FIREBASE_MESSAGING_SENDER_ID: "189043980068",
    BV_FIREBASE_PROJECT_ID: "beyondview-portal-staging",
    BV_FIREBASE_STORAGE_BUCKET: "beyondview-portal-staging.appspot.com",
    BV_FIREBASE_STORAGE_PUBLIC_HOST: "https://storage.googleapis.com",
    BV_FIREBASE_STORAGE_PUBLIC_TEMPLATE: "{host}/{bucket}/{object}",
    BV_FIREBASE_STORAGE_RESTRICTED_HOST: "https://firebasestorage.googleapis.com",
    BV_FIREBASE_STORAGE_RESTRICTED_TEMPLATE: "{host}/v0/b/{bucket}/o/{object}?alt=media&token={token}",
    BV_FIREBASE_STORAGE_ASIA_BUCKET: "asia.public-staging.beyondview.com",
    BV_FIREBASE_STORAGE_EUR_BUCKET: "eur.public-staging.beyondview.com",
    BV_FIREBASE_STORAGE_NAM_BUCKET: "nam.public-staging.beyondview.com"
  },
  production: {
    BV_FIREBASE_HOST_URL: "https://app.beyondview.com",
    BV_FIREBASE_API_KEY: "AIzaSyAU0Ym9kfHRJJMdD6S_46BhQy7QUKgpLR0",
    BV_FIREBASE_APP_ID: "1:611358345998:web:b38a2a9f9a5ea316e6a55f",
    BV_FIREBASE_AUTH_DOMAIN: "beyondview-portal.firebaseapp.com",
    BV_FIREBASE_MEASUREMENT_ID: "G-1ZKJ97SG38",
    BV_FIREBASE_MESSAGING_SENDER_ID: "611358345998",
    BV_FIREBASE_PROJECT_ID: "beyondview-portal",
    BV_FIREBASE_STORAGE_BUCKET: "beyondview-portal.appspot.com",
    BV_FIREBASE_STORAGE_PUBLIC_HOST: "https://storage.googleapis.com",
    BV_FIREBASE_STORAGE_PUBLIC_TEMPLATE: "{host}/{bucket}/{object}",
    BV_FIREBASE_STORAGE_RESTRICTED_HOST: "https://firebasestorage.googleapis.com",
    BV_FIREBASE_STORAGE_RESTRICTED_TEMPLATE: "{host}/v0/b/{bucket}/o/{object}?alt=media&token={token}",
    BV_FIREBASE_STORAGE_ASIA_BUCKET: "asia.public.beyondview.com",
    BV_FIREBASE_STORAGE_EUR_BUCKET: "eur.public.beyondview.com",
    BV_FIREBASE_STORAGE_NAM_BUCKET: "nam.public.beyondview.com"
  }
};
var stringKeys = [
  "BV_FIREBASE_API_KEY",
  "BV_FIREBASE_APP_ID",
  "BV_FIREBASE_AUTH_DOMAIN",
  "BV_FIREBASE_MEASUREMENT_ID",
  "BV_FIREBASE_MESSAGING_SENDER_ID",
  "BV_FIREBASE_PROJECT_ID",
  "BV_FIREBASE_STORAGE_BUCKET",
  "BV_FIREBASE_STORAGE_PUBLIC_HOST",
  "BV_FIREBASE_STORAGE_PUBLIC_TEMPLATE",
  "BV_FIREBASE_STORAGE_RESTRICTED_HOST",
  "BV_FIREBASE_STORAGE_RESTRICTED_TEMPLATE",
  "BV_FIREBASE_SERVER",
  "BV_FIREBASE_STORAGE_ASIA_BUCKET",
  "BV_FIREBASE_STORAGE_EUR_BUCKET",
  "BV_FIREBASE_STORAGE_NAM_BUCKET"
];
var numberKeys = [
  "BV_FIREBASE_AUTH_PORT",
  "BV_FIREBASE_FIRESTORE_PORT",
  "BV_FIREBASE_STORAGE_PORT"
];
var config;
function assignOverrides(target, overrides) {
  for (const key of stringKeys) {
    const value = overrides[key];
    if (value !== void 0) {
      target[key] = String(value);
    }
  }
  for (const key of numberKeys) {
    const value = overrides[key];
    if (value !== void 0) {
      target[key] = Number(value);
    }
  }
}
function createConfig(overrides) {
  if (config) {
    throw new Error("createConfig cannot be called more than once");
  }
  const environment2 = getEnvironment();
  config = defaults[environment2];
  if (!config) {
    throw new Error(`Invalid environment: ${environment2}`);
  }
  if (overrides) {
    assignOverrides(config, overrides);
  }
  return config;
}
function useConfig() {
  if (!config) {
    throw new Error("createConfig must be called before useConfig");
  }
  return config;
}

// ../common/src/Customer.ts
var Customer;
((Customer2) => {
  function getDisplayName(customer, fallback) {
    return customer?.profile?.name || fallback;
  }
  Customer2.getDisplayName = getDisplayName;
  function getImageUrl(customer, tag, levelIndex) {
    if (!customer?.id) {
      return "";
    }
    const path = `customers/${customer.id}`;
    return Upload.getUrl(path, customer.images[tag], { tag, levelIndex });
  }
  Customer2.getImageUrl = getImageUrl;
  function getImageUrlSet(customer, tag) {
    if (!customer?.id) {
      return "";
    }
    const path = `customers/${customer.id}`;
    return Upload.getUrlSet(path, customer.images[tag], { tag });
  }
  Customer2.getImageUrlSet = getImageUrlSet;
})(Customer || (Customer = {}));

// ../common/src/CustomerData.ts
var CustomerData2;
((CustomerData3) => {
  let Role;
  ((Role2) => {
    Role2["Agent"] = "agent";
    Role2["Owner"] = "owner";
  })(Role = CustomerData3.Role || (CustomerData3.Role = {}));
  let Flags;
  ((Flags2) => {
    Flags2["Archived"] = "archived";
  })(Flags = CustomerData3.Flags || (CustomerData3.Flags = {}));
})(CustomerData2 || (CustomerData2 = {}));

// ../common/src/Data.ts
var Data;
((Data2) => {
  function filter(data, callback) {
    const clean = {};
    for (const [key, value] of Object.entries(data)) {
      const filtered = callback ? callback(value, key) : value;
      if (filtered !== void 0) {
        clean[key] = filtered;
      }
    }
    return clean;
  }
  Data2.filter = filter;
  function extract(snapshot) {
    const exists = snapshot?.exists;
    if (!snapshot || !exists || typeof exists === "function" && exists()) {
      return null;
    }
    const data = snapshot.data();
    Object.defineProperty(data, "id", {
      value: snapshot.id,
      writable: false,
      enumerable: false
    });
    return data ?? null;
  }
  Data2.extract = extract;
})(Data || (Data = {}));

// ../common/src/InstanceData.ts
var InstanceData;
((InstanceData2) => {
  let Status;
  ((Status2) => {
    Status2["Inserting"] = "inserting";
    Status2["Starting"] = "starting";
    Status2["Running"] = "running";
    Status2["Stopping"] = "stopping";
    Status2["Stopped"] = "stopped";
    Status2["Deleting"] = "deleting";
  })(Status = InstanceData2.Status || (InstanceData2.Status = {}));
})(InstanceData || (InstanceData = {}));

// ../common/src/MediaVersion.ts
function getPath({ propertyId, versionId }) {
  return `properties/${propertyId}/mediaVersions/${versionId}`;
}
var Media;
((Media2) => {
  function getPosterUrl(target) {
    const { region, itemIndex, source, type, levelIndex } = target;
    const options = { itemIndex, type, levelIndex };
    return Version.getUrl(region, getPath(target), source, options);
  }
  Media2.getPosterUrl = getPosterUrl;
  function getPosterUrlSet(target) {
    const { region, itemIndex, source, type, levelIndex } = target;
    const options = { itemIndex, type, levelIndex };
    return Version.getUrlSet(region, getPath(target), source, options);
  }
  Media2.getPosterUrlSet = getPosterUrlSet;
  function getImageUrl(target) {
    const { region, itemIndex, source, type, levelIndex } = target;
    const options = { itemIndex, type, levelIndex };
    return Version.getUrl(region, getPath(target), source, options);
  }
  Media2.getImageUrl = getImageUrl;
  function getImageUrlSet(target) {
    const { region, itemIndex, source, type, levelIndex } = target;
    const options = { itemIndex, type, levelIndex };
    return Version.getUrlSet(region, getPath(target), source, options);
  }
  Media2.getImageUrlSet = getImageUrlSet;
  function getVideoUrl(target) {
    const { region, itemIndex, source, type, levelIndex } = target;
    const options = { itemIndex, type, levelIndex };
    return Version.getUrl(region, getPath(target), source, options);
  }
  Media2.getVideoUrl = getVideoUrl;
  function getSourceLabels(version) {
    const sources = {};
    for (const [itemIndex, item] of version.items.entries()) {
      if (item.label !== void 0) {
        sources[`item-${itemIndex}`] = item.label;
      }
      if (item.annotations) {
        for (const [annIndex, ann] of item.annotations.entries()) {
          if (ann.label !== void 0) {
            sources[`item-${itemIndex}-ann-${annIndex}`] = ann.label;
          }
        }
      }
    }
    return sources;
  }
  Media2.getSourceLabels = getSourceLabels;
  function getLocalizedLabels(version, languageCode) {
    return Object.assign(
      getSourceLabels(version),
      version.messages?.[languageCode] || {}
    );
  }
  Media2.getLocalizedLabels = getLocalizedLabels;
})(Media || (Media = {}));

// ../common/src/MediaVersionData.ts
var MediaVersionData2;
((MediaVersionData4) => {
  let ItemType;
  ((ItemType2) => {
    ItemType2["Image"] = "image";
    ItemType2["Video"] = "video";
  })(ItemType = MediaVersionData4.ItemType || (MediaVersionData4.ItemType = {}));
  let ContextMode;
  ((ContextMode2) => {
    ContextMode2[ContextMode2["None"] = 0] = "None";
    ContextMode2[ContextMode2["View"] = 1] = "View";
  })(ContextMode = MediaVersionData4.ContextMode || (MediaVersionData4.ContextMode = {}));
})(MediaVersionData2 || (MediaVersionData2 = {}));

// ../common/src/ModelVersion.ts
function getPath2({ propertyId, versionId }) {
  return `properties/${propertyId}/modelVersions/${versionId}`;
}
var Model;
((Model2) => {
  function getMapUrl(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex };
    return Version.getUrl(region, getPath2(target), source, options);
  }
  Model2.getMapUrl = getMapUrl;
  function getMapUrlSet(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex };
    return Version.getUrlSet(region, getPath2(target), source, options);
  }
  Model2.getMapUrlSet = getMapUrlSet;
  function getThumbUrl(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex, viewIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex, viewIndex };
    return Version.getUrl(region, getPath2(target), source, options);
  }
  Model2.getThumbUrl = getThumbUrl;
  function getThumbUrlSet(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex, viewIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex, viewIndex };
    return Version.getUrlSet(region, getPath2(target), source, options);
  }
  Model2.getThumbUrlSet = getThumbUrlSet;
  function getPosterUrl(target) {
    const { region, source, type, levelIndex } = target;
    const options = { type, levelIndex };
    return Version.getUrl(region, getPath2(target), source, options);
  }
  Model2.getPosterUrl = getPosterUrl;
  function getPosterUrlSet(target) {
    const { region, source, type, levelIndex } = target;
    const options = { type, levelIndex };
    return Version.getUrlSet(region, getPath2(target), source, options);
  }
  Model2.getPosterUrlSet = getPosterUrlSet;
  function getSourceLabels(version) {
    const sources = {};
    for (const [areaIndex, area] of version.areas.entries()) {
      if (area.label !== void 0) {
        sources[`area-${areaIndex}`] = area.label;
      }
      for (const [layerIndex, layer] of area.layers.entries()) {
        if (layer.label !== void 0) {
          sources[`area-${areaIndex}-layer-${layerIndex}`] = layer.label;
        }
      }
    }
    return sources;
  }
  Model2.getSourceLabels = getSourceLabels;
  function getLocalizedLabels(version, languageCode) {
    return Object.assign(
      getSourceLabels(version),
      version.messages?.[languageCode] || {}
    );
  }
  Model2.getLocalizedLabels = getLocalizedLabels;
})(Model || (Model = {}));

// ../common/src/NodeData.ts
var NodeData;
((NodeData2) => {
  let Flag;
  ((Flag2) => {
    Flag2["Ready"] = "ready";
    Flag2["Closing"] = "closing";
    Flag2["Closed"] = "closed";
  })(Flag = NodeData2.Flag || (NodeData2.Flag = {}));
  let Role;
  ((Role2) => {
    Role2["Display"] = "display";
    Role2["Relay"] = "relay";
  })(Role = NodeData2.Role || (NodeData2.Role = {}));
  let Status;
  ((Status2) => {
    Status2["Idle"] = "idle";
    Status2["Starting"] = "starting";
    Status2["Loading"] = "loading";
    Status2["Running"] = "running";
    Status2["Stopping"] = "stopping";
  })(Status = NodeData2.Status || (NodeData2.Status = {}));
})(NodeData || (NodeData = {}));

// ../common/src/PeerData.ts
var PeerData;
((PeerData2) => {
  let Flag;
  ((Flag2) => {
    Flag2["Ready"] = "ready";
    Flag2["Host"] = "host";
    Flag2["Closing"] = "closing";
    Flag2["Closed"] = "closed";
  })(Flag = PeerData2.Flag || (PeerData2.Flag = {}));
  let Role;
  ((Role2) => {
    Role2["Display"] = "display";
    Role2["Relay"] = "relay";
    Role2["User"] = "user";
  })(Role = PeerData2.Role || (PeerData2.Role = {}));
})(PeerData || (PeerData = {}));

// ../common/src/Property.ts
var Property;
((Property2) => {
  function getLocationTitle({ address, place, region }) {
    return [address, place, region].filter((v) => !!v).join(", ");
  }
  Property2.getLocationTitle = getLocationTitle;
  function getLocationTitlePrimary({ address }) {
    return address;
  }
  Property2.getLocationTitlePrimary = getLocationTitlePrimary;
  function getLocationTitleSecondary({ place, region }) {
    return [place, region].filter((v) => !!v).join(", ");
  }
  Property2.getLocationTitleSecondary = getLocationTitleSecondary;
  function getTitle({ title, location }) {
    return title || getLocationTitle(location);
  }
  Property2.getTitle = getTitle;
  function getTitlePrimary({ title, location }) {
    return title || getLocationTitlePrimary(location);
  }
  Property2.getTitlePrimary = getTitlePrimary;
  function getTitleSecondary({ location }) {
    return getLocationTitleSecondary(location);
  }
  Property2.getTitleSecondary = getTitleSecondary;
  function getTitleTertiary({ location }) {
    return location.country;
  }
  Property2.getTitleTertiary = getTitleTertiary;
  function getSlug(property) {
    return getTitle(property).replace(/[^\w\d]/g, "-").replace(/-{2,}/g, "-");
  }
  Property2.getSlug = getSlug;
  function getLocationSortKey(location) {
    return [location.country, location.region, location.place, location.address].join("_").toLowerCase().replace(/\s/g, "_");
  }
  Property2.getLocationSortKey = getLocationSortKey;
  function getImageUrl(property, tag, levelIndex) {
    if (!property?.id) {
      return "";
    }
    const path = `properties/${property.id}`;
    return Upload.getUrl(path, property.images[tag], { tag, levelIndex });
  }
  Property2.getImageUrl = getImageUrl;
  function getImageUrlSet(property, tag) {
    if (!property?.id) {
      return "";
    }
    const path = `properties/${property.id}`;
    return Upload.getUrlSet(path, property.images[tag], { tag });
  }
  Property2.getImageUrlSet = getImageUrlSet;
})(Property || (Property = {}));

// ../common/src/PropertyData.ts
var PropertyData2;
((PropertyData3) => {
  let Role;
  ((Role2) => {
    Role2["Agent"] = "agent";
  })(Role = PropertyData3.Role || (PropertyData3.Role = {}));
  let Flags;
  ((Flags2) => {
    Flags2["Archived"] = "archived";
    Flags2["Completed"] = "completed";
  })(Flags = PropertyData3.Flags || (PropertyData3.Flags = {}));
})(PropertyData2 || (PropertyData2 = {}));

// ../common/src/PublicationData.ts
var PublicationData;
((PublicationData2) => {
  let Status;
  ((Status2) => {
    Status2["Queued"] = "queued";
    Status2["Active"] = "active";
    Status2["Completed"] = "completed";
    Status2["Failed"] = "failed";
  })(Status = PublicationData2.Status || (PublicationData2.Status = {}));
  let ContentType;
  ((ContentType2) => {
    ContentType2["Media"] = "media";
    ContentType2["Space"] = "space";
    ContentType2["Model"] = "model";
  })(ContentType = PublicationData2.ContentType || (PublicationData2.ContentType = {}));
  let ManifestType;
  ((ManifestType2) => {
    ManifestType2["Bundle"] = "bundle";
    ManifestType2["Media"] = "media";
    ManifestType2["Space"] = "space";
    ManifestType2["Model"] = "model";
  })(ManifestType = PublicationData2.ManifestType || (PublicationData2.ManifestType = {}));
  let SourceKind;
  ((SourceKind2) => {
    SourceKind2["Image"] = "image";
    SourceKind2["Video"] = "video";
    SourceKind2["Equirect"] = "equirect";
    SourceKind2["Cube"] = "cube";
  })(SourceKind = PublicationData2.SourceKind || (PublicationData2.SourceKind = {}));
})(PublicationData || (PublicationData = {}));

// ../common/src/SessionData.ts
var SessionData;
((SessionData2) => {
  let Flag;
  ((Flag2) => {
    Flag2["Display"] = "display";
    Flag2["Meeting"] = "meeting";
    Flag2["Closing"] = "closing";
    Flag2["Closed"] = "closed";
    Flag2["Dev"] = "dev";
  })(Flag = SessionData2.Flag || (SessionData2.Flag = {}));
})(SessionData || (SessionData = {}));

// ../common/src/Signal.ts
var Signal;
((Signal2) => {
  let MediaKind;
  ((MediaKind2) => {
    MediaKind2["Audio"] = "audio";
    MediaKind2["Video"] = "video";
  })(MediaKind = Signal2.MediaKind || (Signal2.MediaKind = {}));
  let DisplayMessage;
  ((DisplayMessage2) => {
    let Action;
    ((Action2) => {
      Action2["Start"] = "start";
      Action2["Stop"] = "stop";
    })(Action = DisplayMessage2.Action || (DisplayMessage2.Action = {}));
  })(DisplayMessage = Signal2.DisplayMessage || (Signal2.DisplayMessage = {}));
  let MeetingMessage;
  ((MeetingMessage2) => {
    let Action;
    ((Action2) => {
      Action2["Start"] = "start";
      Action2["Stop"] = "stop";
    })(Action = MeetingMessage2.Action || (MeetingMessage2.Action = {}));
  })(MeetingMessage = Signal2.MeetingMessage || (Signal2.MeetingMessage = {}));
  let ChatMessage;
  ((ChatMessage2) => {
    let Type;
    ((Type2) => {
      Type2["Message"] = "message";
      Type2["Reply"] = "reply";
      Type2["Reaction"] = "reaction";
      Type2["Redaction"] = "redaction";
      Type2["Revision"] = "revision";
    })(Type = ChatMessage2.Type || (ChatMessage2.Type = {}));
  })(ChatMessage = Signal2.ChatMessage || (Signal2.ChatMessage = {}));
})(Signal || (Signal = {}));

// ../common/src/SmsData.ts
var SmsData;
((SmsData2) => {
  let Status;
  ((Status2) => {
    Status2["Accepted"] = "accepted";
    Status2["Sending"] = "sending";
    Status2["Sent"] = "sent";
    Status2["Failed"] = "failed";
    Status2["Delivered"] = "delivered";
  })(Status = SmsData2.Status || (SmsData2.Status = {}));
})(SmsData || (SmsData = {}));

// ../common/src/SpaceVersion.ts
function getPath3({ propertyId, versionId }) {
  return `properties/${propertyId}/spaceVersions/${versionId}`;
}
var Space;
((Space2) => {
  function getMapUrl(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex };
    return Version.getUrl(region, getPath3(target), source, options);
  }
  Space2.getMapUrl = getMapUrl;
  function getMapUrlSet(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex };
    return Version.getUrlSet(region, getPath3(target), source, options);
  }
  Space2.getMapUrlSet = getMapUrlSet;
  function getThumbUrl(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex, viewIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex, viewIndex };
    return Version.getUrl(region, getPath3(target), source, options);
  }
  Space2.getThumbUrl = getThumbUrl;
  function getThumbUrlSet(target) {
    const { region, source, type, levelIndex, areaIndex, layerIndex, viewIndex } = target;
    const options = { type, levelIndex, areaIndex, layerIndex, viewIndex };
    return Version.getUrlSet(region, getPath3(target), source, options);
  }
  Space2.getThumbUrlSet = getThumbUrlSet;
  function getTileUrlCallback(target) {
    const { areaIndex, viewIndex, layerIndex, type } = target;
    return ({ x: column = "", y: row = "", z: levelIndex = "", face = "" }) => {
      const { levels, previewName, name } = target.source;
      const isPreview = !!levels[levelIndex]?.isPreview;
      const file = Text.parseTemplate(
        isPreview ? previewName : name,
        { type, levelIndex, areaIndex, layerIndex, viewIndex, face, column, row }
      );
      const path = getPath3(target);
      const object = `${path}/${file}`;
      const url = Storage.getObjectPublicUrl(object, target.region);
      if (isPreview) {
        const level = levels[levelIndex];
        const y = level.faceOrder.indexOf(face) / 6;
        return { url, rect: { x: 0, y, width: 1, height: 1 / 6 } };
      }
      return { url };
    };
  }
  Space2.getTileUrlCallback = getTileUrlCallback;
  function getSourceLabels(version) {
    const sources = {};
    for (const [areaIndex, area] of version.areas.entries()) {
      if (area.label !== void 0) {
        sources[`area-${areaIndex}`] = area.label;
      }
      for (const [layerIndex, layer] of area.layers.entries()) {
        if (layer.label !== void 0) {
          sources[`area-${areaIndex}-layer-${layerIndex}`] = layer.label;
        }
      }
      for (const [viewIndex, view] of area.views.entries()) {
        if (view.label !== void 0) {
          sources[`area-${areaIndex}-view-${viewIndex}`] = view.label;
        }
      }
    }
    return sources;
  }
  Space2.getSourceLabels = getSourceLabels;
  function getLocalizedLabels(version, languageCode) {
    return Object.assign(
      getSourceLabels(version),
      version.messages?.[languageCode] || {}
    );
  }
  Space2.getLocalizedLabels = getLocalizedLabels;
})(Space || (Space = {}));

// ../common/src/Storage.ts
var Storage;
((Storage2) => {
  let Region;
  ((Region2) => {
    Region2["Default"] = "default";
    Region2["NorthAmerica"] = "nam";
    Region2["Europe"] = "eur";
    Region2["Asia"] = "asia";
  })(Region = Storage2.Region || (Storage2.Region = {}));
  const buckets = {
    default: "BV_FIREBASE_STORAGE_BUCKET",
    nam: "BV_FIREBASE_STORAGE_NAM_BUCKET",
    eur: "BV_FIREBASE_STORAGE_EUR_BUCKET",
    asia: "BV_FIREBASE_STORAGE_ASIA_BUCKET"
  };
  function getBucket(region) {
    const options = useConfig();
    if (region === void 0 || getEnvironment() === "development" /* Development */) {
      region = "default" /* Default */;
    }
    if (!buckets[region]) {
      throw new Error(`Invalid region: ${region}`);
    }
    const bucket = options[buckets[region]];
    if (!bucket) {
      throw new Error(`Invalid regional storage bucket: ${buckets[region]}`);
    }
    return String(bucket);
  }
  Storage2.getBucket = getBucket;
  function getObjectRestrictedUrl(object, token) {
    const options = useConfig();
    return Text.parseTemplate(
      options.BV_FIREBASE_STORAGE_RESTRICTED_TEMPLATE,
      {
        host: options.BV_FIREBASE_STORAGE_RESTRICTED_HOST,
        bucket: options.BV_FIREBASE_STORAGE_BUCKET,
        object: encodeURIComponent(object),
        token
      }
    );
  }
  Storage2.getObjectRestrictedUrl = getObjectRestrictedUrl;
  function getObjectPublicUrl(object, region) {
    const options = useConfig();
    const bucket = getBucket(region);
    return Text.parseTemplate(
      options.BV_FIREBASE_STORAGE_PUBLIC_TEMPLATE,
      { host: options.BV_FIREBASE_STORAGE_PUBLIC_HOST, bucket, object }
    );
  }
  Storage2.getObjectPublicUrl = getObjectPublicUrl;
  function getPreferredMediaType(availableTypes, preferredType) {
    if (preferredType && availableTypes.includes(preferredType)) {
      return preferredType;
    }
    return availableTypes.find((type) => type !== preferredType);
  }
  Storage2.getPreferredMediaType = getPreferredMediaType;
  function getPreferredMediaLevelIndex(levels, constraints) {
    let levelIndex = levels.length - 1;
    if (window && !constraints) {
      constraints = { width: window.innerWidth, height: window.innerHeight };
    }
    if (constraints?.width !== void 0 && constraints?.height !== void 0) {
      let { width, height } = constraints;
      for (let i = levelIndex - 1; i >= 0; i--) {
        const level = levels[i];
        if (width > level.width && height > level.height) {
          break;
        }
        levelIndex = i;
      }
    }
    return levelIndex;
  }
  Storage2.getPreferredMediaLevelIndex = getPreferredMediaLevelIndex;
})(Storage || (Storage = {}));

// ../common/src/Text.ts
var Text;
((Text2) => {
  function parseTemplate(template = "", params = {}) {
    for (const param in params) {
      template = template.replace(new RegExp(`{(${param})}`, "g"), params[param]);
    }
    return template;
  }
  Text2.parseTemplate = parseTemplate;
  function getInitials(value) {
    return typeof value === "string" ? value.split(" ").map((n) => n[0]).join("") : "";
  }
  Text2.getInitials = getInitials;
  function getColor(seed, saturation = 75, lightness = 75, opacity) {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
      hash = Math.imul(31, hash) + seed.charCodeAt(i) | 0;
    }
    const h = hash % 360;
    if (opacity !== void 0) {
      return `hsla(${h},${saturation}%,${lightness}%,${opacity})`;
    }
    return `hsl(${h},${saturation}%,${lightness}%)`;
  }
  Text2.getColor = getColor;
  function isValidEmailAddress(email) {
    return email.length >= 1 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  Text2.isValidEmailAddress = isValidEmailAddress;
})(Text || (Text = {}));

// ../common/src/TranslationData.ts
var TranslationData4;
((TranslationData5) => {
  let Status;
  ((Status2) => {
    Status2["Pending"] = "pending";
    Status2["Submitted"] = "submitted";
    Status2["Complete"] = "complete";
    Status2["Failed"] = "failed";
  })(Status = TranslationData5.Status || (TranslationData5.Status = {}));
})(TranslationData4 || (TranslationData4 = {}));

// ../common/src/Upload.ts
var Upload;
((Upload2) => {
  function getUrl(path, media, options = {}) {
    let levelIndex = options.levelIndex;
    const max = media.levels.length - 1;
    if (typeof levelIndex !== "number" || levelIndex < 0 || levelIndex > max) {
      levelIndex = max;
    }
    const type = Storage.getPreferredMediaType(media.types, options.type);
    const name = Text.parseTemplate(media.name, { ...options, levelIndex, type });
    const object = `${path}/${name}`;
    return Storage.getObjectRestrictedUrl(object, media.token);
  }
  Upload2.getUrl = getUrl;
  function getUrlSet(path, media, options = {}) {
    const type = Storage.getPreferredMediaType(media.types, options.type);
    return media.levels.map((level, levelIndex) => {
      const name = Text.parseTemplate(media.name, { ...options, levelIndex, type });
      const object = `${path}/${name}`;
      let url = Storage.getObjectRestrictedUrl(object, media.token);
      if (levelIndex < media.levels.length - 1) {
        url += ` ${level.width}w`;
      }
      return url;
    }).join(", ");
  }
  Upload2.getUrlSet = getUrlSet;
})(Upload || (Upload = {}));

// ../common/src/User.ts
var User;
((User2) => {
  function getDisplayName(user, fallback) {
    return user?.profile?.name || user?.email || fallback;
  }
  User2.getDisplayName = getDisplayName;
  function getTitle(user) {
    return user?.profile?.title;
  }
  User2.getTitle = getTitle;
  function getImageUrl(user, tag, levelIndex) {
    if (!user?.id) {
      return "";
    }
    const path = `users/${user.id}`;
    return Upload.getUrl(path, user.images[tag], { tag, levelIndex });
  }
  User2.getImageUrl = getImageUrl;
  function getImageUrlSet(user, tag) {
    if (!user?.id) {
      return "";
    }
    const path = `users/${user.id}`;
    return Upload.getUrlSet(path, user.images[tag], { tag });
  }
  User2.getImageUrlSet = getImageUrlSet;
})(User || (User = {}));

// ../common/src/UserData.ts
var UserData2;
((UserData3) => {
  let Flags;
  ((Flags2) => {
    Flags2["Admin"] = "admin";
    Flags2["Staff"] = "staff";
    Flags2["Deleted"] = "deleted";
  })(Flags = UserData3.Flags || (UserData3.Flags = {}));
})(UserData2 || (UserData2 = {}));

// ../common/src/Version.ts
var Version;
((Version2) => {
  let VersionCollection;
  ((VersionCollection2) => {
    VersionCollection2["Media"] = "mediaVersions";
    VersionCollection2["Space"] = "spaceVersions";
    VersionCollection2["Model"] = "modelVersions";
  })(VersionCollection = Version2.VersionCollection || (Version2.VersionCollection = {}));
  function getUrl(region, path, media, options = {}) {
    let levelIndex = options.levelIndex;
    const max = media.levels.length - 1;
    if (levelIndex === void 0 || levelIndex < 0) {
      levelIndex = 0;
    } else if (levelIndex > max) {
      levelIndex = max;
    }
    const type = Storage.getPreferredMediaType(media.types, options.type);
    const name = Text.parseTemplate(media.name, { ...options, levelIndex, type });
    const object = `${path}/${name}`;
    return Storage.getObjectPublicUrl(object, region);
  }
  Version2.getUrl = getUrl;
  function getUrlSet(region, path, media, options = {}) {
    const type = Storage.getPreferredMediaType(media.types, options.type);
    return media.levels.map((level, levelIndex) => {
      const name = Text.parseTemplate(media.name, { ...options, levelIndex, type });
      const object = `${path}/${name}`;
      return Storage.getObjectPublicUrl(object, region) + ` ${level.width}w`;
    }).join(", ");
  }
  Version2.getUrlSet = getUrlSet;
})(Version || (Version = {}));

// ../common/src/VersionData.ts
var VersionData4;
((VersionData5) => {
  let Status;
  ((Status2) => {
    Status2["Uploading"] = "UPLOADING";
    Status2["Done"] = "DONE";
    Status2["Error"] = "ERROR";
  })(Status = VersionData5.Status || (VersionData5.Status = {}));
  let Flags;
  ((Flags2) => {
    Flags2["Archived"] = "archived";
  })(Flags = VersionData5.Flags || (VersionData5.Flags = {}));
  let AnnotationAnchorType;
  ((AnnotationAnchorType2) => {
    AnnotationAnchorType2["Point"] = "point";
  })(AnnotationAnchorType = VersionData5.AnnotationAnchorType || (VersionData5.AnnotationAnchorType = {}));
  let AnnotationTargetType;
  ((AnnotationTargetType2) => {
    AnnotationTargetType2["View"] = "view";
  })(AnnotationTargetType = VersionData5.AnnotationTargetType || (VersionData5.AnnotationTargetType = {}));
})(VersionData4 || (VersionData4 = {}));

// ../common/src/index.ts
var environment;
function setEnvironment(value) {
  if (environment !== void 0) {
    throw new Error("Cannot change environment once set");
  }
  if (value === "development") {
    environment = "development" /* Development */;
  } else if (value === "staging") {
    environment = "staging" /* Staging */;
  } else if (value === "production") {
    environment = "production" /* Production */;
  } else {
    throw new Error(
      `Invalid environment: "${value}". test, development, staging or production expected`
    );
  }
  return environment;
}
function getEnvironment() {
  return environment;
}

// src/firebase.ts
import firebase from "firebase-8/app";
import "firebase-8/firestore";
import "firebase-8/auth";
import "firebase-8/storage";
import "firebase-8/analytics";
var services = void 0;
function useFirebase() {
  if (!services) {
    const usingEmulator = getEnvironment() === "development" /* Development */;
    const config2 = useConfig();
    const {
      BV_FIREBASE_API_KEY,
      BV_FIREBASE_APP_ID,
      BV_FIREBASE_AUTH_DOMAIN,
      BV_FIREBASE_MEASUREMENT_ID,
      BV_FIREBASE_MESSAGING_SENDER_ID,
      BV_FIREBASE_PROJECT_ID,
      BV_FIREBASE_STORAGE_BUCKET
    } = config2;
    const app = firebase.initializeApp({
      apiKey: BV_FIREBASE_API_KEY,
      appId: BV_FIREBASE_APP_ID,
      authDomain: BV_FIREBASE_AUTH_DOMAIN,
      measurementId: BV_FIREBASE_MEASUREMENT_ID,
      messagingSenderId: BV_FIREBASE_MESSAGING_SENDER_ID,
      projectId: BV_FIREBASE_PROJECT_ID,
      storageBucket: BV_FIREBASE_STORAGE_BUCKET
    });
    const db = app.firestore();
    const auth = app.auth();
    const storage = app.storage();
    const analytics = usingEmulator ? void 0 : app.analytics();
    if (usingEmulator) {
      const {
        BV_FIREBASE_SERVER,
        BV_FIREBASE_AUTH_PORT,
        BV_FIREBASE_FIRESTORE_PORT,
        BV_FIREBASE_STORAGE_PORT
      } = config2;
      if (!BV_FIREBASE_AUTH_PORT || !BV_FIREBASE_FIRESTORE_PORT || !BV_FIREBASE_SERVER || !BV_FIREBASE_STORAGE_PORT) {
        throw new Error("Invalid Firebase emulator configuration");
      }
      auth.useEmulator(`http://${BV_FIREBASE_SERVER}:${BV_FIREBASE_AUTH_PORT}`);
      db.useEmulator(BV_FIREBASE_SERVER, BV_FIREBASE_FIRESTORE_PORT);
      storage.useEmulator(BV_FIREBASE_SERVER, BV_FIREBASE_STORAGE_PORT);
    }
    services = { app, db, auth, storage, analytics };
  }
  return services;
}
export {
  Customer,
  CustomerData2 as CustomerData,
  Data,
  Environment,
  InstanceData,
  Media,
  MediaVersionData2 as MediaVersionData,
  Model,
  NodeData,
  PeerData,
  Property,
  PropertyData2 as PropertyData,
  PublicationData,
  SessionData,
  Signal,
  SmsData,
  Space,
  Storage,
  Text,
  TranslationData4 as TranslationData,
  Upload,
  User,
  UserData2 as UserData,
  Version,
  VersionData4 as VersionData,
  createConfig,
  getEnvironment,
  setEnvironment,
  useConfig,
  useFirebase
};
